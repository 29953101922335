import React, { useEffect } from "react";
import stl from "./glavnaya.module.scss";
import SliderFront from "../../../pages/admin/slider/front/SliderFront";
// import Carousel from "../../other/Carousel/Carousel";
import { useDispatch, useSelector } from "react-redux";
// import { selectAllSlider } from "../../../app/redux/store/sliderStore/sliderSlice";
// import { loadSlides } from "../../../app/http/slideApi";
import {
  promoImage,
  selectAllTypes,
  selectSelectedUrl,
  setSelectedId,
  statusPromo,
} from "../../../app/redux/store/typeStore/typeSlice";
import { Link } from "react-router-dom";
import { loadbyNavbar } from "../../../app/http/typeApi";

const Glavnaya = () => {
  const dispatch = useDispatch();
  const navcat = useSelector(selectAllTypes);
  const selectedUrl = useSelector(selectSelectedUrl);
  const promo = useSelector(statusPromo);
  const promoImg = useSelector(promoImage);

  // console.log(navcat, "списко категорий");

  const handleLinkClick = (id) => {
    setSelectedId(id);
  };
  // const isCategoryActive = true;

  const categoryNav = navcat.map((type) => (
    <div className={stl.category} key={type.id}>
      <Link
        to={`/category/${type.id}`}
        onClick={() => handleLinkClick(type.id)}>
        {type.imageUrl && (
          <div className={stl.imageWrapper}>
            <img
              className={stl.typeimg}
              src={process.env.REACT_APP_API_URL + "category/" + type?.imageUrl}
              alt=""
            />
            <div className={stl.caption}>
              <h2 className={stl.captionText}>{type.name}</h2>
            </div>
          </div>
        )}
      </Link>
    </div>
  ));

  useEffect(() => {
    dispatch(loadbyNavbar());
  }, [selectedUrl]);

  return (
    <>
      <div className={stl.wrapper}>
        <SliderFront />
        {/* <Carousel images={imageload} currentImg={0} /> */}
      </div>
      <div className={stl.wrapperCategory}>
        {promo && (
          <div className={stl.category}>
            <Link to={`category/promo`}>
              {promoImg && (
                <div className={stl.imageWrapper}>
                  <img
                    className={stl.typeimg}
                    src={process.env.REACT_APP_API_URL + "category/" + promoImg}
                    alt=""
                  />
                  <div className={stl.caption}>
                    <h2 className={stl.captionText}>АКЦИИ</h2>
                  </div>
                </div>
              )}
            </Link>
          </div>
        )}
        {categoryNav}
      </div>

      <hr />

      <p className={stl.h4}>Цветут 64</p>
      <div className={stl.text}>
        <p>Дорогие друзья! </p>
        <p>Рады вас приветствовать на нашем сайте!</p>

        <p>
          Уже более 10 лет вы доверяете нам самые важные и трепетные моменты в
          вашей жизни!
        </p>

        <p>
          Мы расширяем доступность заказа цветов максимально для всех! Теперь
          заказы можно оформить на сайте.
        </p>

        <p>
          Лаконичность, удобство, простота и скорость оформления заказа — это
          то, что вам обязательно понравится.
        </p>

        <p>
          Качество, цены и ассортимент цветов будут продолжать вас радовать и
          удивлять!
        </p>

        <p>
          В честь открытия сайта всем, кто оформил заказ именно через сайт,
          доставка по Саратову и Энгельсу бесплатна! *
        </p>

        <p>С любовью и заботой, ваши CVETUT_64</p>
      </div>
      <div className={stl.smallText}>
        <p>
          *Бесплатная доставка осуществляется по центральным районам Саратова и
          Энгельса.
        </p>
        <p>
          По удаленным районам оплата доставки осуществляется за минусом
          стоимости доставки центральных районов.
        </p>
        <p>Сроки проведения данной акции до 20 апреля 2025 года.</p>
      </div>
    </>
  );
  //маршруты доставки
};

export default Glavnaya;
