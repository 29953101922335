import React, { useEffect } from "react";
import stl from "./confirm.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { toggleStatus } from "../../app/redux/store/orderSlice/orderSlice";

const Confirm = () => {
  const order = useSelector((state) => state?.order?.data);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(toggleStatus({ statusInfo: "idle" }));
  }, []);
  return (
    <div className={stl.wrapper}>
      <h1 style={{ textAlign: "center" }}>Спасибо за заказ, {order?.name}</h1>
      <div>
        <p>
          Номер заказа:
          <span style={{ fontWeight: "bold", color: "green" }}>
            {" "}
            {order?.order}
          </span>
        </p>
        {order?.deliveryOption === "Самовывоз" ? (
          <>
            <p>
              Адрес самовывоза:{" "}
              <span style={{ fontWeight: "bold" }}>{order?.address}</span>
            </p>
            <p>
              Время самовывоза:{" "}
              <span style={{ fontWeight: "bold", color: "red" }}>
                {order?.deliveryTime}
              </span>
            </p>
            <p>
              <span style={{ fontWeight: "bold", color: "red" }}>
                Предупреждение:
              </span>{" "}
              Заказ аннулируется, если самовывоз не был осуществлен в указанную
              дату до 18:00
            </p>
            <p>
              Состояние оплаты:{" "}
              <span style={{ fontWeight: "bold", color: "blue" }}>
                {order?.variablePay}
              </span>
            </p>
            <p>
              Стоимость заказа:
              <span style={{ fontWeight: "bold", color: "orangered" }}>
                {order?.FullOrderPrice}
              </span>{" "}
              руб
            </p>
          </>
        ) : (
          <>
            <p>
              Доставка по адресу:{" "}
              <span style={{ fontWeight: "bold" }}>{order?.address}</span>
            </p>
            {order?.kv && (
              <p>
                Номер квартиры:{" "}
                <span style={{ fontWeight: "bold" }}>{order?.kv}</span>
              </p>
            )}
            <p>
              Время доставки:{" "}
              <span style={{ fontWeight: "bold" }}>{order?.deliveryTime}</span>
            </p>
            <p>
              Состояние оплаты:{" "}
              <span style={{ fontWeight: "bold" }}>{order?.variablePay}</span>
            </p>
            <p>
              Стоимость заказа:{" "}
              <span style={{ fontWeight: "bold" }}>
                {order?.FullOrderPrice}
              </span>{" "}
              руб
            </p>
            <p>
              Стоимость доставки:{" "}
              <span style={{ fontWeight: "bold" }}>{order?.priceDelivery}</span>{" "}
              руб
            </p>
          </>
        )}

        {order?.comments !== "" && <p>Открытка: {order?.comments}</p>}
      </div>
      <h4>Товары в заказе:</h4>

      <div className={stl.products}>
        {order?.items?.map((item) => (
          <div className={stl.wrapItem} key={item.id}>
            <img
              src={
                process.env.REACT_APP_API_URL +
                "images/products/" +
                item.image[0]?.mini
              }
              className={stl.img}
              alt={item?.name}
            />
            <p>{item?.name}</p>
            <p>Количество: {item?.quantity} шт</p>
            <p>Количество цветов в букете: {item?.dimensions?.qnt} шт </p>
            <p>Высота стебля: {item?.dimensions?.height} см </p>
            {item?.options && <p>Цвет упаковки: {item?.options?.цвета} </p>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Confirm;
