import React from "react";
import styles from "./about.module.scss";

const Contacts = () => {
  return (
    <div className={styles.about}>
      <h1 className={styles.title}>Контакты</h1>
      <p className={styles.description}>
        ИНДИВИДУАЛЬНЫЙ ПРЕДПРИНИМАТЕЛЬ Рыбина Мария Андреевна
      </p>
      <p className={styles.description}>ИНН 643203361401</p>
      <p className={styles.description}>ОГРН 324645700021890</p>
      <p className={styles.description}>
        {" "}
        Расчётный счёт: 40802810629130009807
      </p>
      <p className={styles.description}>
        {" "}
        Банк: ФИЛИАЛ "НИЖЕГОРОДСКИЙ" АО "АЛЬФА-БАНК"
      </p>
      <p className={styles.description}> БИК: 042202824</p>

      <p className={styles.description}> Кор. счёт: 30101810200000000824</p>
      <p className={styles.description}>
        {" "}
        Юридический адрес компании: Мясницкая, 54, Саратовская область, г.
        Саратов
      </p>
    </div>
  );
};

export default Contacts;
