import { createAsyncThunk } from "@reduxjs/toolkit";
import { $host } from "./index";

export const addSlide = createAsyncThunk(
  "@@slider/create-slide",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await $host.post("api/slide", formData);
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const addFullSlide = createAsyncThunk(
  "@@slider/create-slide",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await $host.post("api/slide", formData);
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const saveImage = createAsyncThunk(
  "@@images/save-slide",
  async ({ id, formData }, { rejectWithValue }) => {
    try {
      const response = await $host.patch(`api/slide/${id}`, formData);
      // console.log(response);
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const loadSlides = createAsyncThunk("@@slider/load-slides", () =>
  $host.get("api/slide")
);

export const updateSlide = createAsyncThunk(
  "@@slider/update-slide",
  async ({ id, formData }, { rejectWithValue, dispatch }) => {
    try {
      const response = await $host.patch(`api/slide/${id}`, formData);
      console.log(response);
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const deleteSlide = createAsyncThunk(
  "@@slider/delete-slide",
  async (img) => {
    const res = await $host.delete(`api/slide/${img}`);
    console.log(res.data);
    return res.data;
  }
);
export const upSlide = createAsyncThunk("@@slider/up-slide", async (id) => {
  const res = await $host.get(`api/slide/up/${id}`);
  // console.log(res.data);
  return res.data;
});
export const downSlide = createAsyncThunk("@@slider/down-slide", async (id) => {
  const res = await $host.get(`api/slide/down/${id}`);
  // console.log(res.data);
  return res.data;
});

// дальше нужно править...
