import React, { useEffect, useState } from "react";
import ImageCropActions from "../../../../features/imageCrop/ImageCropActions";
import Modal from "../../../../shared/ui/modal/Modal";
import { ReactComponent as SvgImage } from "./image.svg";
import { loadimageActions } from "../../../../app/http/imageApi";
import { useDispatch, useSelector } from "react-redux";

const Actions = () => {
  const [modalActive, setModalActive] = useState(false);
  const dispatch = useDispatch();
  const imageAction = useSelector((state) => state.galery.imageAction);

  // const fileInputRef = useRef(null);
  const handleSvgPlusClick = () => {
    setModalActive(true);
  };

  useEffect(() => {
    dispatch(loadimageActions());
  }, []);
  return (
    <div>
      <h1> Фото раздела Акций</h1>
      {imageAction !== null ? (
        <img
          // className={stl.typeimg}
          src={process.env.REACT_APP_API_URL + "category/" + imageAction}
          alt=""
          onClick={handleSvgPlusClick}
        />
      ) : (
        <SvgImage
          // className={stl.svg_plus}
          onClick={handleSvgPlusClick}
        />
      )}

      <Modal visible={modalActive} setVisible={setModalActive}>
        {" "}
        <ImageCropActions setVisible={setModalActive} />{" "}
      </Modal>
    </div>
  );
};

export default Actions;
