import React, { useEffect, useState } from "react";
import styles from "./deviceItem.module.scss";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loadDeviceByUrl } from "../../app/http/deviceApi";
import GaleryFront from "../../widgets/GaleryFront/GaleryFront";
import { selectDeviceByUrl } from "../../app/redux/store/deviceStore/deviceSlice";
import SlideDeviceItem from "../../features/slideDeviceItem/SlideDeviceItem";
import BuyButton from "../../shared/ui/bybutton/byButton";
import { selectLoadedPricesById } from "../../app/redux/store/priceStore/priceSlice";
import { loadPrice } from "../../app/http/priceApi";
import PriceSelector from "../../features/prices/PriceSelector/PriceSelector";
import { loadDeviceOptionId } from "../../app/http/optionSelectApi";
import OptionsDeviceFrontColors from "../../features/deviceOptionsFront/OptionsDeviceFrontColors";
import OptionsDeviceFrontModels from "../../features/deviceOptionsFront/OptionsDeviceFrontModels";
import ImageDeviceControl from "../../features/deviceOptionsFront/ImageDeviceControl";
import OptionsDeviceFrontAdvanced from "../../features/deviceOptionsFront/OptionsDeviceFrontAdvanced";
import ByButtonItem from "../../shared/ui/bybutton/ByButtonItem";
import DeliveryDateTime from "../../features/DeliveryDateTime/DeliveryDateTime";

const DeviceItem = () => {
  const { id } = useParams();
  const idPart = id.split("/").pop();
  const [filteredImages, setFilteredImages] = useState([]);
  // const loadedPricesById = useSelector((state) =>
  //   selectLoadedPricesById(state, id)
  // );
  const dimensions = useSelector((state) => state.totalPrice.dimensions);

  const loadedPricesById = useSelector((state) =>
    selectLoadedPricesById(state, idPart)
  );

  const device = useSelector(selectDeviceByUrl);
  const imageProducts = device?.imageProducts;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadDeviceByUrl(idPart));
  }, [idPart]);
  useEffect(() => {
    dispatch(loadPrice(idPart));
  }, [idPart, dispatch]);
  useEffect(() => {
    console.log(idPart, "test IDPART");
    dispatch(loadDeviceOptionId(idPart));
  }, [idPart, dispatch]);

  useEffect(() => {
    if (imageProducts && dimensions.height > 0) {
      const filteredProducts = imageProducts.filter(
        (product) => product.height === dimensions.height
      );
      setFilteredImages(filteredProducts);
    } else if (
      imageProducts &&
      dimensions.height == 0 &&
      dimensions?.qnt != 0
    ) {
      const filteredProducts = imageProducts.filter(
        (product) => product.quantity == dimensions.qnt
      );
      setFilteredImages(filteredProducts);
    } else if (
      imageProducts &&
      dimensions?.height == 0 &&
      dimensions?.qnt == 0
    ) {
      setFilteredImages(imageProducts);
    }
  }, [dimensions, imageProducts]);

  if (!device || !device?.name) {
    return <h1>Загрузка...</h1>;
  } else if (device === "Product is not available") {
    return <h1>Товар временно не доступен...</h1>;
  }
  const {
    name,
    about,
    tarea,
    price,
    qnt = 0,
    height = 0,
    // imageProducts,
    pricechecked,
    priceboxes,
    PriceBox,
    salePrice,
    isInSale,
    url,
  } = device;

  return (
    <>
      {/* <h1>{name}</h1> */}
      <div className={styles.wrapper}>
        {/* <DeliveryDateTime /> */}
        {/* <OptionsDeviceFrontModels />
        <div>
          <ImageDeviceControl />
          <OptionsDeviceFrontColors />
        </div> */}
        <div className={styles.gallery}>
          {/* {imageProducts && <GaleryFront images={filteredImages} />} */}
          {imageProducts && <GaleryFront images={imageProducts} />}
        </div>

        <div className={styles.right}>
          <PriceSelector
            id={idPart}
            pricechecked={pricechecked}
            priceboxes={priceboxes}
            name={name}
            dimensions={{ qnt, height }}
            prices={loadedPricesById}
            price={isInSale ? salePrice : price}
            PriceBox={PriceBox}
          />

          <OptionsDeviceFrontAdvanced />
          <OptionsDeviceFrontColors />

          {/* <OptionsDeviceFrontAdvanced /> */}
          <div className={styles.button}>
            <ByButtonItem
              name={name}
              id={id}
              tarea={tarea}
              url={url}
              // lngt={lngt}
              // qnt={qnt}
              // price={price}
              image={imageProducts}
            />
          </div>
        </div>
      </div>
      <p className={styles.leftdesc}>О товаре:</p>

      <div className={styles.description}>
        <p>{about}</p>
      </div>
      <div className={styles.description}>
        <h1 className={styles.h1}>Правила ухода и хранения цветов!!!</h1>
        <p>
          Даря своим любимым цветы, мы надеемся , что радость будет длиться
          максимально долго, и рассчитываем , что цветы будут сохранять свою
          свежесть и привлекательный внешний вид неделями. Мы гарантируем Вам
          свежесть цветов на момент покупки, ведь их доставляют нам сразу после
          срезки с крупнейших цветочных комбинатов России и стран ближнего
          зарубежья напрямую. Но не стоит забывать, что цветок - это живой и
          очень хрупкий, требовательный к условиям жизнеобеспечения, хранения и
          транспортировки товар. Конечно, создать идеальные условия хранения в
          квартирных условиях не получится , хотя бы потому , что идеальная
          температура хранения цветка от +3 до +5 градусов тепла, а так же
          необходима влажность воздуха нехарактерная нашим с Вами условиями
          проживания. Поэтому каждый цветок «выживает» в тех условиях , которые
          ему предложены с разным временным диапазоном. Срок жизни каждого
          цветка даже в идеальных условиях отличается, собственно как и всего
          живого в этом мире. Мы Вам подскажем , как попытаться создать
          максимально комфортные условия, соблюсти элементарные правила и
          избежать грубейших ошибок, которые приводят к необратимой гибели
          цветка .
        </p>
      </div>
      <div className={styles.description}>
        <h1 className={styles.h1}>Правила транспортировки.</h1>
        <div>При самовывозе необходимо:</div>
        <ul>
          <li>
            - При длительной транспортировке (более 1 часа) попросить сделать
            вам аквабокс (он у нас бесплатный 🤗* в конфигураторе присутствует
            дизайнерский аквабокс за 150р)
          </li>
          <li>
            - Избегать нахождение цветка как в минусовых так и высоких
            температурных режимах.
          </li>
        </ul>
        <p>
          И то и другое приведёт к гибели цветка даже при кратковременном
          нахождении в данных условиях .
        </p>
      </div>
      <div className={styles.description}>
        <h1 className={styles.h1}>Условия хранения:</h1>
        {/* <div> */}
        <ul>
          <li>- Избегать резких перепадов температур .</li>
          <li>
            - Перед тем как поставить цветы в вазу, необходимо подрезать цветы
            (не ножницами!) садовым секатором либо ножом наискось каждый стебель
            цветка , желательно под проточной водой , и сразу поставить в вазу с
            прохладной водой.
          </li>
          <li>
            - Уровень воды в вазе зависит от цветка. Розам требуется много воды
            , как минимум 1/3 часть стебля должна находиться в воде.
            Хризантемам, альстромерии , амариллисам, гиацинтам и тд воды нужно
            совсем немного 3-5 см.
          </li>
          <li>
            - Упаковочный материал лучше оставлять на цветах особенно в период
            отопления и летом! При низкой влажности воздуха в помещении можно
            опрыскивать из пульверизатора листья цветов.
          </li>
          <li>
            - Ежедневно необходимо промывать стебли цветов под проточной водой,
            делать свежий срез и ставить в вазу со свежей чистой прохладной
            водой, предварительно промыв саму вазу с моющими средствами.
          </li>
          <li>
            - Избегать нахождения в близи отопительных приборов, кондиционеров,
            прямых солнечных лучей, сквозняков.
          </li>
        </ul>
        <p>Мы делаем для Вас красоту, ее дальнейшая судьба в ваших руках 🌹</p>
        {/* </div> */}
      </div>
      <div className={styles.description}>
        <h1 className={styles.h1}>Правила ухода и хранения цветов!!!</h1>
        <p>
          Букеты могут иметь незначительные отличия от фото букетов (раскрытие
          бутонов, цвет упаковки, лент, боксов, если Вы не указывали при
          оформлении заказа определенный цвет ) Так же за собой оставляем право
          на подборку упаковочного материала максимально близкого по цвету из
          наличия, если выбранная упаковка не будет в наличии в момент
          выполнения заказа.
        </p>
        <h1 className={styles.h1}>
          ОТСУТСТВИЕ УПАКОВКИ ЗНАЧИТЕЛЬНО СОКРАЩАЕТ СРОК ЖИЗНИ ЦВЕТОВ!
        </h1>
        <p>
          Цветы относятся к скоропортящимся товарам , претензии по качеству или
          внешнему виду принимаются только в момент получения заказа. Для этого
          необходимо написать в раздел «Претензии» При нарушении условий
          самостоятельной транспортировки , а так же хранения претензии НЕ
          ПРИНИМАЮТСЯ ! Настоятельно рекомендуем ознакомиться с правилами ухода
          и хранения цветов . Не соблюдение ведёт к ПРЕЖДЕВРЕМЕННОЙ ГИБЕЛИ
          ЦВЕТОВ!
        </p>
      </div>
    </>
  );
};

export default DeviceItem;
