import React, { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import stl from "./deliveryDateTime.module.scss";
import {
  subDays,
  addDays,
  eachDayOfInterval,
  setMinutes,
  setHours,
} from "date-fns";

// Регистрируем русскую локаль глобально
registerLocale("ru", ru);
// Устанавливаем русскую локаль по умолчанию
setDefaultLocale("ru");

const DeliveryDateTime = ({ deliveryOption, onTimeChange }) => {
  const currentDate = new Date();
  const currentHour = currentDate.getHours();
  const currentMinutes = currentDate.getMinutes();
  const now = new Date();
  const today = now.getDate();
  const tomorrow = addDays(now, 1).getDate();

  let initialSelectedDate;

  // Логика для начальной даты в зависимости от времени
  if (currentHour < 9) {
    initialSelectedDate = setHours(setMinutes(new Date(), 0), 9); // Если текущее время до 9:00, устанавливаем на 9:00
  } else if (currentHour >= 9 && currentHour <= 17) {
    initialSelectedDate = new Date(currentDate.getTime() + 90 * 60 * 1000); // В противном случае, устанавливаем через 90 минут от текущего времени
  } else {
    initialSelectedDate = setHours(setMinutes(addDays(new Date(), 1), 0), 10); // Если текущее время после 17:30, устанавливаем на 10:00 следующего дня
  }

  const [selectedDate, setSelectedDate] = useState(initialSelectedDate);
  const [initialBtn, setInitialBtn] = useState(false);
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button type="button" className={stl.custom} onClick={onClick} ref={ref}>
      {initialBtn ? value : "Выберите время и дату"}
    </button>
  ));

  const handleDateChange = (date) => {
    const now = new Date();
    const currentHour = now.getHours();
    const selectedDay = date.getDate();
    const today = now.getDate();
    const tomorrow = addDays(now, 1).getDate();

    // Если сейчас после 17:30, запрещаем выбор текущей даты
    if (currentHour >= 17 && selectedDay === today) {
      setSelectedDate(tomorrowMinTime); // Автоматически ставим завтрашний минимум
      onTimeChange(tomorrowMinTime);
      return;
    }

    // Определяем минимальное время для выбранного дня
    let minTime;
    if (selectedDay === today) {
      minTime =
        deliveryOption === "Доставка"
          ? setHours(setMinutes(now, 30), 10) // Доставка с 10:30
          : setHours(setMinutes(now, 0), 10); // Самовывоз с 10:00
    } else if (selectedDay === tomorrow) {
      minTime = tomorrowMinTime;
    } else {
      minTime = dayAfterTomorrowMinTime;
    }

    // Определяем максимальное время
    const maxTime =
      deliveryOption === "Доставка"
        ? setHours(setMinutes(date, 0), 20)
        : setHours(setMinutes(date, 0), 18);

    // Корректируем выбранное время
    if (date < minTime) {
      date = minTime;
    }
    if (date > maxTime) {
      date = maxTime;
    }

    setSelectedDate(date);
    setInitialBtn(true);
    onTimeChange(date);
  };
  const filterTime = (time) => {
    const timebomb = new Date(currentDate.getTime() + 90 * 60 * 1000);
    const selectedTime = new Date(time);
    return timebomb < selectedTime.getTime();
  };

  const includeDateIntervals = [{ start: Date(), end: addDays(new Date(), 2) }];
  const includedDates = includeDateIntervals.flatMap((interval) =>
    eachDayOfInterval(interval)
  );

  const tomorrowMinTime = setHours(
    setMinutes(addDays(new Date(), 1), deliveryOption === "Доставка" ? 30 : 0),
    deliveryOption === "Доставка" ? 10 : 10
  );
  const dayAfterTomorrowMinTime = setHours(
    setMinutes(addDays(new Date(), 2), deliveryOption === "Доставка" ? 30 : 0),
    deliveryOption === "Доставка" ? 7 : 9
  );

  // Массив исключаемых дат (если время после 17:30, исключаем текущую дату)
  // const excludedDates = currentHour >= 17 ? [new Date()] : [];
  const excludedDates =
    currentHour >= 17 && currentMinutes >= 30 ? [new Date()] : [];

  useEffect(() => {
    setInitialBtn(false);
    onTimeChange(""); // Сбрасываем значение в родительском компоненте
  }, [deliveryOption]);

  return (
    <div className={stl.wrap}>
      <DatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        showTimeSelect
        filterTime={filterTime}
        includeDateIntervals={[
          { start: subDays(new Date(), 1), end: addDays(new Date(), 2) },
        ]}
        disabledKeyboardNavigation
        highlightDates={includedDates}
        minTime={
          selectedDate &&
          (selectedDate.getDate() === today
            ? now.getHours() < 9
              ? deliveryOption === "Доставка"
                ? setHours(setMinutes(now, 30), 10) // Доставка с 10:30
                : setHours(setMinutes(now, 0), 10) // Самовывоз с 10:00
              : new Date(now.getTime() + 90 * 60 * 1000) // Минимум через 90 минут
            : selectedDate.getDate() === tomorrow
            ? tomorrowMinTime
            : dayAfterTomorrowMinTime)
        }
        maxTime={
          deliveryOption === "Доставка"
            ? setHours(setMinutes(new Date(), 0), 20)
            : setHours(setMinutes(new Date(), 0), 18)
        }
        timeFormat="HH:mm"
        timeIntervals={30}
        dateFormat="MMMM d, yyyy HH:mm"
        timeCaption="Время"
        excludeDates={excludedDates}
        customInput={<ExampleCustomInput />}
      />
    </div>
  );
};

export default DeliveryDateTime;
