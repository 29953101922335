import { createSlice } from "@reduxjs/toolkit";
import {
  createDevice,
  delDevice,
  editDevice,
  loadDeviceByUrl,
  loadDevicePromo,
  loadDevices,
  loadDevicesToType,
} from "../../../http/deviceApi";

// action/reducer/dispatch('forward')
const initialState = {
  status: "idle",
  error: null,
  list: [],
  selectedDevice: null,
};

const deviceSlice = createSlice({
  name: "@@type",
  initialState,
  reducers: {
    sortDevicesByPrice: (state, action) => {
      const sortOption = action.payload;
      state.list.sort((a, b) => {
        if (sortOption === "asc") {
          return a.price - b.price; // Сортировка по возрастанию цены
        } else if (sortOption === "desc") {
          return b.price - a.price; // Сортировка по убыванию цены
        }
        return 0;
      });
    },
    setDevicesNull: (state, action) => {
      state.list = [];
    },
    // setSelectedDevice: (state, action) => {
    //   const height = action.payload;
    //   if (state.selectedDevice) {
    //     state.selectedDevice.imageProducts =
    //       state.selectedDevice.imageProducts.filter(
    //         (product) => product.height == height
    //       );
    //   }
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadDevices.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(loadDevices.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload || action.meta.error;
      })
      .addCase(loadDevices.fulfilled, (state, action) => {
        state.status = "received";
        state.list = action.payload.data.rows;
        //надо получить объект типа []
      })
      .addCase(loadDevicePromo.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(loadDevicePromo.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload || action.meta.error;
      })
      .addCase(loadDevicePromo.fulfilled, (state, action) => {
        state.status = "received";
        state.list = action.payload.data;
        //надо получить объект типа []
      })
      .addCase(loadDevicesToType.fulfilled, (state, action) => {
        state.status = "received";
        state.list = action.payload.data;
      })
      .addCase(loadDeviceByUrl.fulfilled, (state, action) => {
        state.status = "received";
        // console.log(
        //   action.payload.data,
        //   "что у нас туттттsdjhdfhd fhd fhdfh d fhdf hdfh d fh dfh gs dgd fg dfg d fg dfgdfg dfg dfg dfg d fg ergd fh т"
        // );
        state.selectedDevice = action.payload.data;
      })

      .addCase(createDevice.fulfilled, (state, action) => {
        state.status = "received";
        state.error = null;
        state.list.push(action.payload.data);
      })
      .addCase(createDevice.rejected, (state, action) => {
        state.status = "rejected";
        if (action.payload) {
          state.error = action.payload; // Извлекаем сообщение об ошибке из тела ответа
        } else {
          state.error = "Something went wrong"; // Если нет сообщения об ошибке, то выводим стандартное сообщение
        }
      })
      .addCase(delDevice.fulfilled, (state, action) => {
        state.status = "received";
        state.list = state.list.filter((item) => item.id !== action.payload);
      })
      .addCase(editDevice.fulfilled, (state, action) => {
        state.status = "received";
        // state.list = state.list.find();
        const item = state.list.find(
          (item) => item.id === action.payload.data.id
        );
        console.log(item, "это айтем");
        if (item) {
          for (let prop in item) {
            item[prop] = action.payload.data[prop];
          }
          // item.name = action.payload.data.name;
          // item.price = action.payload.data.price; и т.д.
        }
      })
      .addCase(editDevice.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload || action.meta.error;
      });
    // .addCase(editDevice.fulfilled, (state, action) => {
    //     const editedProduct = action.payload.data;
    //     const index = state.list.findIndex((product) => product.id === editedProduct.id);
    //     state.list[index] = editedProduct;
    //   })
  },
});

export const selectAllDevices = (state) => state.device.list;

export const { sortDevicesByPrice } = deviceSlice.actions;

// export const selectDeviceByUrl = (state, url) => {
//   return state.device.list.find((item) => item.url === url) || {};
// };
export const selectDeviceByUrl = (state) => state.device.selectedDevice;
export const { setDevicesNull } = deviceSlice.actions;
export const selectDeviceStatus = (state) => state.device.status;
export const selectDeviceError = (state) => state.device.error;
export const deviceReducer = deviceSlice.reducer;
