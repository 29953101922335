import React from "react";
import { ReactComponent as SvgTime } from "./svg/time.svg";
import stl from "./dostavka.module.scss";

const Dostavka = () => {
  return (
    <div className={stl.wrapper}>
      <p>Условия доставки</p>
      <p>Данный тариф только для центральных районов Саратова и Энгельса</p>
      <div className={stl.block}>
        <div className={stl.left}>
          <SvgTime className={stl.svg} />
        </div>
        <div className={stl.right}>
          <div>
            <p>
              <ul>
                <li>Ранняя с 7:30 до 9:30 350₽</li>
                <li>Дневная с 9:30 до 10:30 290₽ с 10:30 до 16:00 250₽</li>
                <li>Вечерняя с 16:00 до 20:00 350₽</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
      <hr className={stl.hr} />
      <p>
        Заказы на текущий день и ранее утро следующего дня можно оформить до
        17:30 часов
      </p>

      <p>
        При оформлении заказа после 17:30 часов текущего дня ближайшая доставка
        возможна на следующий день с 10:30 часов
      </p>
      <hr className={stl.hr} />

      <p>
        Время указанное при оформлении заказа является ориентировочным. Доставка
        осуществляется +- 30-60 минут к указанному времени. (В час-пик и
        неблагоприятные погодные условия, доставка может задерживаться)
      </p>
      <hr className={stl.hr} />
    </div>
  );
};

export default Dostavka;
