import { createAsyncThunk } from "@reduxjs/toolkit";
import { $host } from "./index";

export const createCart = createAsyncThunk(
  // '@@types/create-type',
  // (type) => $host.post('api/type', type)
  "@@types/create-type",
  async (type, { rejectWithValue }) => {
    try {
      const response = await $host.post("api/type", type);
      return response;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const loadTypes = createAsyncThunk("@@types/load-types", () =>
  $host.get("api/type")
);

export const delType = createAsyncThunk("@@types/delete-types", async (id) => {
  const res = await $host.delete(`api/type/${id}`);
  return res.data;
});

export const editType = createAsyncThunk(
  "@@types/edit-types",
  async ({ id, name }) => {
    const res = await $host.patch(`api/type/${id}`, { name });
    return res.data;
  }
);
export const cartValidate = createAsyncThunk(
  "@@types/cart-validate",
  async (cartValidate) => {
    const res = await $host.post(`api/cart/validate`, cartValidate);
    return res.data;
  }
);

// () => {
//     const { data } = await $host.get('api/type')
//     return data
// }
