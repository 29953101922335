import { createAsyncThunk } from "@reduxjs/toolkit";
import { $host } from "./index";

export const createOrder = createAsyncThunk(
  "@@order/create-order",
  async ({ order, allItems, allPrice }, { rejectWithValue }) => {
    try {
      // Ваш код обработки состава заказа (allItems), если необходимо
      // Например, можете просто добавить его к заказу в нужном формате

      // Предположим, вы хотите добавить allItems к свойству order.items
      const orderWithItems = {
        ...order,
        items: allItems,
        // totalPrice: allPrice,
      };

      const response = await $host.post("api/order", orderWithItems);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const createZayavka = createAsyncThunk(
  "@@order/create-order",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await $host.post("api/order/zayavka", formData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const createCallBack = createAsyncThunk(
  "@@order/create-order",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await $host.post("api/order/callback", formData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const confirmPayKassa = createAsyncThunk(
  "@@order/confirm-order",
  async (data, { rejectWithValue }) => {
    try {
      const response = await $host.post("api/order/waitpay", data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const loadAllOrders = createAsyncThunk(
  "@@order/load-orders-all",
  async (_, { rejectWithValue }) => {
    try {
      const response = await $host.get("api/order/");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
