import React, { useState, useEffect } from "react";
import stl from "./suggest.module.scss";
import axios from "axios";
import { TextField } from "@mui/material";

const Suggest = ({ onAddressSelect }) => {
  const [searchText, setSearchText] = useState("");
  const [result, setResult] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const apiKey = "bbb4c705-dad4-491f-9c29-883d2c795f96";
  const baseURL = "https://suggest-maps.yandex.ru/v1/suggest";
  const [inputFocused, setInputFocused] = useState(true);
  const bboxCoordinates = "45.9,51.5~46.23,51.6";

  useEffect(() => {
    if (searchText.trim() === "") {
      setSearchResults([]);
      return;
    }

    axios
      .get(baseURL, {
        params: {
          text: searchText,
          strict_bounds: 1,
          bbox: bboxCoordinates,
          results: 4,
          attrs: "uri",
          print_address: 1,
          spn: "0.1,0.1",
          apikey: apiKey,
        },
      })
      .then((response) => {
        console.log(response.data.results, "что на входе");

        // Фильтрация результатов, чтобы оставить только адреса с номером дома
        const filteredResults = response.data.results.filter((result) => {
          const address = result?.address?.formatted_address || "";
          return /\d+/.test(address); // Проверяем, есть ли в адресе число (номер дома)
        });

        setSearchResults(filteredResults);
      })
      .catch((error) => console.error("Error:", error));
  }, [searchText]);

  const handleInputChange = (event) => {
    setSearchText(event.target.value);
    setInputFocused(true);
  };

  const handleChange = (resultText) => {
    setSearchText(resultText.address?.formatted_address);
    setResult(resultText.address);
    // onAddressSelect(resultText.address?.formatted_address);
    // onAddressSelect(resultText.address);
    setInputFocused(false);
  };
  const handleItemClick = (e) => {
    e.preventDefault();
    // setSearchText(resultText.address?.formatted_address);
    // onAddressSelect(resultText.address?.formatted_address);
    onAddressSelect(result);
    // setInputFocused(false);
  };

  return (
    <div className={stl.wrap}>
      <TextField
        style={{ width: "100%", margin: "0" }}
        label="Введите адрес доставки"
        type="text"
        value={searchText}
        onChange={handleInputChange}
        // onBlur={handleInputBlur}
      />
      {searchResults?.length > 0 && inputFocused && (
        <ul className={stl.ul}>
          {searchResults.map((result, index) => (
            <li
              key={index}
              className={stl.li}
              onClick={() => handleChange(result)}>
              {result?.address?.formatted_address}
            </li>
          ))}
        </ul>
      )}
      <button className={stl.btn} onClick={handleItemClick}>
        Выбрать адрес
      </button>
    </div>
  );
};

export default Suggest;
