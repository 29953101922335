import React, { useEffect, useRef, useState } from "react";
import stl from "./galery.module.scss";
import { ReactComponent as SvgPlus } from "./svg/plus.svg";
import { ReactComponent as SvgEmpty } from "./svg/image.svg";
import { ReactComponent as SvgDelete } from "./svg/delete.svg";
import ReactCrop, {
  convertToPixelCrop,
  makeAspectCrop,
  centerCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import setCanvasPreview from "../../shared/ui/cropsImage/cropsImage";

// import { addImage, selectAllGallery } from '../../app/redux/store/imageSlice/imageSlice';
import { selectAllGallery } from "../../app/redux/store/imageSlice/imageSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  addImageFull,
  addImageMini,
  deleteImage,
  loadimagesId,
} from "../../app/http/imageApi";
import { height } from "@mui/system";

const Galery = ({ editId }) => {
  const imageload = useSelector((state) => selectAllGallery(state));

  const dispatch = useDispatch();
  const [aspectRatio, setAspectRatio] = useState(1.5625);

  // const ASPECT_RATIO = 4 / 3;
  const MIN_DIMENSION = 150;
  const idForMobile = useSelector((state) => state.galery.id);
  const [currentImg, setCurrentImg] = useState(0);
  const [isImageCropped, setIsImageCropped] = useState(false);
  const [imgNext, setImgNext] = useState(false);
  const [hoveredImg, setHoveredImg] = useState(-1);
  const [crop, setCrop] = useState();
  const imgRef = useRef(null);
  const [property, setProperty] = useState({
    quantity: 0,
    height: 0,
  });
  // const [height, setHeight] = useState(0);

  const previewCanvasRef = useRef(null);
  const [imgSrc, setImgSrc] = useState("");

  const [error, setError] = useState("");

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

    const crop = makeAspectCrop(
      {
        unit: "%",
        width: cropWidthInPercent,
      },
      aspectRatio,
      width,
      height
    );
    const centeredCrop = centerCrop(crop, width, height);
    setCrop(centeredCrop);
  };

  const fileInputRef = useRef(null);

  // const handleClick = (index) => {
  //   setCurrentImg(index);
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProperty((prevProperty) => ({
      ...prevProperty,
      [name]: value,
    }));
  };

  const handlerAddFull = () => {
    const dataUrl = previewCanvasRef.current.toDataURL();
    const blob = dataURItoBlob(dataUrl);
    const file = new File([blob], "cropped_image.png"); // Создаем файл из Blob
    const formData = new FormData();
    formData.append("img", file);
    formData.append("id", editId);

    dispatch(addImageFull(formData));
    setImgNext(true);
    setIsImageCropped(false);
  };
  const handlerAddMini = () => {
    const dataUrl = previewCanvasRef.current.toDataURL();
    const blob = dataURItoBlob(dataUrl);
    const file = new File([blob], "cropped_image.png"); // Создаем файл из Blob
    const formData = new FormData();
    formData.append("img", file);
    formData.append("id", idForMobile);
    formData.append("property", JSON.stringify(property));

    dispatch(addImageMini(formData));
    setImgNext(false);
    setIsImageCropped(false);
    setCrop(null);
    setImgSrc("");
    setProperty({ quantity: 0, height: 0 });
  };

  // Функция для преобразования Data URI в Blob
  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const onSelectFile = (file) => {
    // const file = e.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const imageElement = new Image();
      const imageUrl = reader.result?.toString() || "";
      imageElement.src = imageUrl;

      imageElement.addEventListener("load", (e) => {
        if (error) setError("");
        const { naturalWidth, naturalHeight } = e.currentTarget;
        if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
          setError("Image must be at least 150 x 150 pixels.");
          return setImgSrc("");
        }
      });
      setImgSrc(imageUrl);
    });
    reader.readAsDataURL(file);
  };
  const handlePaste = (e) => {
    const items = e.clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") !== -1) {
        const file = items[i].getAsFile();
        onSelectFile(file);
      }
    }
  };

  const handlerRemove = (e) => {
    dispatch(deleteImage(e));
  };
  const handleSvgPlusClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    if (imgNext) {
      setAspectRatio(1.3333);
    } else {
      // setAspectRatio(0.5625);
      setAspectRatio(1);
    }
  }, [imgNext]);
  useEffect(() => {
    window.addEventListener("paste", handlePaste);
    return () => {
      window.removeEventListener("paste", handlePaste);
    };
  }, []);
  const handleFileInputChange = (e) => {
    const file = e.target.files?.[0];
    onSelectFile(file);
  };

  return (
    <div className={stl.wrapper}>
      {/* <select value={aspectRatio} onChange={handleAspectRatioChange}>
        <option value="2.22">20/9</option>
        <option value="1">1</option>
        <option value="0.56">9/16</option>
      </select> */}
      {/* {imageload.length > 0 ? ( */}
      <div style={{ display: "flex", gap: "1rem" }}>
        <div>
          {imgSrc ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <ReactCrop
                crop={crop}
                onChange={(pixelCrop, percentCrop) => setCrop(percentCrop)}
                // circularCrop
                keepSelection
                aspect={aspectRatio}
                minWidth={MIN_DIMENSION}>
                {" "}
                <img
                  ref={imgRef}
                  // className={stl.bigimg}
                  style={{ maxHeight: "70vh" }}
                  // src={
                  //   process.env.REACT_APP_API_URL + "/" + imageload[currentImg]?.img
                  // }
                  src={imgSrc}
                  alt="Upload"
                  onLoad={onImageLoad}
                />
              </ReactCrop>
              <button
                className={stl.btn}
                onClick={() => {
                  setCanvasPreview(
                    imgRef.current, // HTMLImageElement
                    previewCanvasRef.current, // HTMLCanvasElement
                    convertToPixelCrop(
                      crop,
                      imgRef.current.width,
                      imgRef.current.height
                    )
                  );
                  setIsImageCropped(true);
                  // const dataUrl = previewCanvasRef.current.toDataURL();
                  // updateAvatar(dataUrl);
                  // closeModal();
                }}>
                обрезать
              </button>
            </div>
          ) : (
            <div className={stl.bigimg}>
              <SvgEmpty className={stl.svgimage} />
            </div>
          )}
          {imageload.length < 12 && (
            <div className={stl.add}>
              <SvgPlus className={stl.svg} onClick={handleSvgPlusClick} />
              <input
                type="file"
                style={{ display: "none" }}
                ref={fileInputRef}
                // onChange={onSelectFile}
                onChange={handleFileInputChange}
              />
            </div>
          )}
        </div>
        <div>
          <div>
            <label className={stl.text}>Количество цветов</label>
            <input
              className={stl.inp}
              name="quantity"
              type="number"
              value={property.quantity}
              // defaultValue={props.goods.lngt}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label className={stl.text}>Высота цветка</label>
            <input
              className={stl.inp}
              name="height"
              type="number"
              value={property.height}
              // defaultValue={props.goods.lngt}
              onChange={handleInputChange}
            />
          </div>
        </div>

        {crop && (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <canvas
              ref={previewCanvasRef}
              className="mt-4"
              style={{
                // display: "none",
                border: "1px solid black",
                objectFit: "contain",
                width: 270,
                height: 480,
              }}
            />
            {!imgNext ? (
              <>
                {!isImageCropped ? (
                  <p style={{ textAlign: "center" }}>
                    Сначала обрежьте основное фото
                  </p>
                ) : (
                  <p style={{ textAlign: "center" }}>
                    Теперь загружаем основное фото в фотоальбом
                  </p>
                )}
                {isImageCropped && (
                  <button className={stl.btnAdd} onClick={handlerAddFull}>
                    Добавить изображение в фотоальбом
                  </button>
                )}
              </>
            ) : (
              <>
                {!isImageCropped ? (
                  <p style={{ textAlign: "center" }}>
                    Сначала обрежьте миниатюру
                  </p>
                ) : (
                  <p style={{ textAlign: "center" }}>
                    Теперь загружаем миниатюру в фотоальбом
                  </p>
                )}
                {/* {isImageCropped && property.quantity != 0 && ( */}
                {isImageCropped && (
                  // property.height != 0 &&
                  <button className={stl.btnAdd} onClick={handlerAddMini}>
                    Добавить миниатюру в фотоальбом(фото и миниатюра будут
                    связаны)
                  </button>
                )}
              </>
            )}
          </div>
        )}
      </div>
      {/* <div> */}
      {/* <div className={stl.navGrid}> */}
      <div className={stl.nav}>
        {/* <p>Старые картинки</p> */}
        {imageload &&
          imageload.length > 0 &&
          imageload.map((item, index) => (
            <div
              key={index}
              className={stl.imgWrapper}
              onMouseEnter={() => setHoveredImg(index)}
              onMouseLeave={() => setHoveredImg(-1)}>
              {index === hoveredImg && (
                <SvgDelete
                  className={stl.deleteSvg}
                  onClick={() => handlerRemove(item.id)}
                />
              )}
              <img
                className={stl.img}
                // onClick={() => handleClick(index)}
                src={
                  process.env.REACT_APP_API_URL +
                  "images/products/" +
                  item?.mini
                }
                alt=""
              />
            </div>
          ))}
      </div>
      {/* <div className={stl.nav}>
            <p>Новые картинки</p>
            {newlist &&
              newlist.length > 0 &&
              newlist.map((item, index) => (
                <div
                  key={index}
                  className={stl.imgWrapper}
                  onMouseEnter={() => setHoveredImg(index)}
                  onMouseLeave={() => setHoveredImg(-1)}>
                  {index === hoveredImg && (
                    <SvgDelete
                      className={stl.deleteSvg}
                      onClick={() => handlerRemove(item.id)}
                    />
                  )}
                  <img
                    className={stl.img}
                    // onClick={() => handleClick(index)}
                    src={
                      process.env.REACT_APP_API_URL +
                      "images/" +
                      item?.imageArray[0]?.img
                    }
                    alt=""
                  />
                </div>
              ))}
          </div> */}
    </div>
  );
};

export default Galery;
