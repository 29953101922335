import React, { useState } from "react";
import styles from "./card.module.scss";
import BuyButton from "../../shared/ui/bybutton/byButton";
import { Link } from "react-router-dom";
// import { DEVICE_ROUTE } from "../../app/router/utils/consts";
// import CardGallery from "../cardGallery/CardGallery";
import { useDispatch } from "react-redux";
import { loadDeviceByUrl } from "../../app/http/deviceApi";
import useMediaQuery from "@mui/material/useMediaQuery";
import CardGalleryMobile from "./mobile/CardGalleryMobile";
import CardGalleryDesktop from "./desktop/CardGalleryDesktop";

const Card = (props) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:768px)");
  const CardGallery = isMobile ? CardGalleryMobile : CardGalleryDesktop;
  const {
    id,
    name,
    url,
    price,
    imageProducts,
    isInSale,
    salePrice,
    height,
    qnt,
    tarea,
    priceboxes,
    pricechecked,
    PriceBox,
  } = props;
  // const handleClick = () => {
  //   dispatch(loadDeviceByUrl(id));
  // };
  // console.log(id);
  return (
    <div className={styles.wrap}>
      <Link
        style={{ textDecoration: "none", color: "black" }}
        to={`/product/${id}/${url}`}
        // onClick={handleClick}
      >
        <div className={styles.card}>
          <div className={styles.img}>
            <CardGallery images={imageProducts} />
          </div>

          {pricechecked && (
            <>
              <div className={styles.price}>
                <h2 className={styles.h2}>{name}</h2>
                <span className={styles.span}>Цена</span> от:
                {price} руб.
              </div>

              <div className={styles.block}>
                <p> количество цветов: от 1шт.</p>
                <p> высота стебля : от 40см.</p>
              </div>
            </>
          )}
          {priceboxes && (
            <>
              <div className={styles.price}>
                <h2 className={styles.h2}>{name}</h2>
                <span className={styles.span}>Цена</span> от:
                {PriceBox?.s?.price} руб.
              </div>

              {/* <div className={styles.block}>
                <p> количество цветов: от 1шт.</p>
                <p> высота стебля : от 40см.</p>
              </div> */}
            </>
          )}
          {!pricechecked && !priceboxes && (
            <>
              <div className={styles.price}>
                <h2 className={styles.h2}>{name}</h2>
                {isInSale === true ? (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div>
                      Cтарая цена:
                      <span style={{ textDecoration: "line-through" }}>
                        {price} руб.
                      </span>
                    </div>
                    <div>
                      {" "}
                      Цена по акции:
                      <span className={styles.span}>{salePrice} руб.</span>
                    </div>
                  </div>
                ) : (
                  <>
                    Цена:<span className={styles.span}> {price} руб.</span>
                  </>
                )}
              </div>

              <div className={styles.block}>
                {/* <div className={styles.box}> */}
                {qnt > 0 && <p>количество цветов: {qnt}шт.</p>}
                {height > 0 && <p> высота стебля : {height}см.</p>}

                {/* </div> */}
              </div>
            </>
          )}

          {(pricechecked || priceboxes) && (
            <div className={styles.btn}>
              <Link
                style={{ textDecoration: "none", color: "black" }}
                to={`/product/${id}/${url}`}>
                <div className={styles.button}>Выбрать</div>
              </Link>
            </div>
          )}
          {(!pricechecked || !priceboxes) && (
            <div className={styles.button}>
              <BuyButton
                id={id}
                name={name}
                url={url}
                tarea={tarea}
                dimensions={{ qnt, height }}
                price={isInSale ? salePrice : price}
                image={
                  imageProducts && imageProducts.length > 0 && imageProducts
                }
              />
            </div>
          )}
        </div>
      </Link>
    </div>
  );
};

export default Card;
