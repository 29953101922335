import React, { useEffect, useState } from "react";
import stl from "./navbar.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { ADMIN_ROUTE, LOGIN_ROUTE } from "../../../app/router/utils/consts";
import { useDispatch, useSelector } from "react-redux";
import { setAuth } from "../../../app/redux/store/userStore/authSlice";
import {
  selectAllTypes,
  setSelectedId,
  statusPromo,
} from "../../../app/redux/store/typeStore/typeSlice";
import { loadTypes, loadbyNavbar } from "../../../app/http/typeApi";

const Navbar = () => {
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.isAuth);
  const imageAction = useSelector((state) => state.galery.imageAction);
  // const selectedCity = useSelector((state) => state.city.selectedCity);
  // console.log(selectedCity.url, "url города");
  const promo = useSelector(statusPromo);
  // const [openTypeIndex, setOpenTypeIndex] = useState(null);
  const navigate = useNavigate();
  const logout = () => {
    dispatch(setAuth(false));
    navigate(LOGIN_ROUTE);
  };

  const type = useSelector(selectAllTypes);

  const handleLinkClick = (id) => {
    dispatch(setSelectedId({ id }));
  };

  useEffect(() => {
    dispatch(loadbyNavbar());
  }, [dispatch]);
  return (
    <>
      <nav className={stl.menu}>
        <ul className={stl.elementsMenu}>
          {promo && (
            <Link
              to={`category/promo`}
              className={stl.promo}
              // onClick={() => handleLinkClick("promo")}
            >
              Акции
            </Link>
          )}
          {type.map((c, index) => (
            <li key={index} className={stl.menuItem}>
              <div className={stl.menuItemWrapper}>
                <Link
                  to={`category/${c.id}`}
                  className={stl.navbar_item_header}
                  onClick={() => handleLinkClick(c.id)}>
                  {c.name}
                </Link>
                {c.children?.length > 0 && (
                  <div className={stl.subMenu}>
                    {/* <ul className={stl.subMenu}> */}
                    <ul className={stl.downElementsMenu}>
                      {c.children.map((childType) => (
                        <li key={childType.id} className={stl.menuItemSub}>
                          <Link
                            // to={`${selectedCity.url}/category/${childType.id}`}
                            to={`category/${childType.id}`}
                            className={stl.navbar_item_header}
                            onClick={() => handleLinkClick(childType.id)}>
                            {childType.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </li>
          ))}
        </ul>
        <div className={stl.actions}>
          {isAuth && (
            <h4
              className={stl.navbar_item}
              style={{ cursor: "pointer" }}
              onClick={() => navigate(ADMIN_ROUTE)}>
              Админка
            </h4>
          )}
          {isAuth && (
            <h4
              className={stl.navbar_item}
              style={{ cursor: "pointer" }}
              onClick={logout}>
              Выйти
            </h4>
          )}
        </div>
      </nav>
    </>
  );
};

export default Navbar;
