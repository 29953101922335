import React, { useEffect, useRef, useState } from "react";
import stl from "./crop.module.scss";
import { ReactComponent as SvgPlus } from "./plus.svg";
import { ReactComponent as SvgEmpty } from "./image.svg";
import setCanvasPreview from "../../shared/ui/cropsImage/cropsImage";
import ReactCrop, {
  convertToPixelCrop,
  makeAspectCrop,
  centerCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useDispatch } from "react-redux";
import { addImageType } from "../../app/http/typeApi";
import { addImageActions } from "../../app/http/imageApi";

const ImageCropActions = (props) => {
  const { setVisible = () => {} } = props;
  const [crop, setCrop] = useState();
  const [error, setError] = useState("");
  const [imgSrc, setImgSrc] = useState("");
  const [aspectRatio, setAspectRatio] = useState(0.853);
  const [isImageCropped, setIsImageCropped] = useState(false);

  const dispatch = useDispatch();

  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);

  const MIN_DIMENSION = 150;

  const onSelectFile = (file) => {
    // const file = e.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const imageElement = new Image();
      const imageUrl = reader.result?.toString() || "";
      imageElement.src = imageUrl;

      imageElement.addEventListener("load", (e) => {
        if (error) setError("");
        const { naturalWidth, naturalHeight } = e.currentTarget;
        if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
          setError("Image must be at least 150 x 150 pixels.");
          return setImgSrc("");
        }
      });
      setImgSrc(imageUrl);
    });
    reader.readAsDataURL(file);
  };

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

    const crop = makeAspectCrop(
      {
        unit: "%",
        width: cropWidthInPercent,
      },
      aspectRatio,
      width,
      height
    );
    const centeredCrop = centerCrop(crop, width, height);
    setCrop(centeredCrop);
  };
  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };
  const handlerAddFull = () => {
    const dataUrl = previewCanvasRef.current.toDataURL();
    const blob = dataURItoBlob(dataUrl);
    const file = new File([blob], "cropped_image.png"); // Создаем файл из Blob
    if (file) {
      const formData = new FormData();
      formData.append("img", file);
      formData.append("description", "Акции");
      // formData.append("id", id);
      dispatch(addImageActions(formData));
      setIsImageCropped(false);
      setVisible(false);
    }
  };

  const handlePaste = (e) => {
    const items = e.clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") !== -1) {
        const file = items[i].getAsFile();
        onSelectFile(file);
      }
    }
  };

  const fileInputRef = useRef(null);
  const handleSvgPlusClick = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files?.[0];
    onSelectFile(file);
  };

  useEffect(() => {
    window.addEventListener("paste", handlePaste);
    return () => {
      window.removeEventListener("paste", handlePaste);
    };
  }, []);

  return (
    <div className={stl.wrap}>
      <div className={stl.add}>
        <SvgPlus className={stl.svg} onClick={handleSvgPlusClick} />
        <input
          type="file"
          style={{ display: "none" }}
          ref={fileInputRef}
          // onChange={onSelectFile}
          onChange={handleFileInputChange}
        />
      </div>
      {imgSrc ? (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <ReactCrop
            crop={crop}
            onChange={(pixelCrop, percentCrop) => setCrop(percentCrop)}
            // circularCrop
            keepSelection
            aspect={aspectRatio}
            minWidth={MIN_DIMENSION}>
            {" "}
            <img
              ref={imgRef}
              // className={stl.bigimg}
              style={{ maxHeight: "70vh" }}
              // src={
              //   process.env.REACT_APP_API_URL + "/" + imageload[currentImg]?.img
              // }
              src={imgSrc}
              alt="Upload"
              onLoad={onImageLoad}
            />
          </ReactCrop>
          <button
            className={stl.btn}
            onClick={() => {
              setCanvasPreview(
                imgRef.current, // HTMLImageElement
                previewCanvasRef.current, // HTMLCanvasElement
                convertToPixelCrop(
                  crop,
                  imgRef.current.width,
                  imgRef.current.height
                )
              );
              setIsImageCropped(true);
              // const dataUrl = previewCanvasRef.current.toDataURL();
              // updateAvatar(dataUrl);
              // closeModal();
            }}>
            обрезать
          </button>
        </div>
      ) : (
        <div className={stl.bigimg}>
          <SvgEmpty className={stl.svgimage} />
        </div>
      )}
      {crop && (
        <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <canvas
            ref={previewCanvasRef}
            className="mt-4"
            style={{
              // display: "none",
              border: "1px solid black",
              objectFit: "contain",
              width: 270,
              height: 480,
            }}
          />
          {isImageCropped && (
            <button className={stl.btnAdd} onClick={handlerAddFull}>
              Добавить изображение в АКЦИИ
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default ImageCropActions;
