import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  selectAllTypes,
  setSelectedId,
  statusPromo,
} from "../../../app/redux/store/typeStore/typeSlice";
import { loadbyNavbar } from "../../../app/http/typeApi";
import stl from "./burger.module.scss";
import City from "../../../widgets/cities/City";

const BurgerMenu = ({ isActive, close }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const type = useSelector(selectAllTypes);
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const promo = useSelector(statusPromo);
  // const handleLinkClick = (id) => {
  //   dispatch(setSelectedId({ id }));
  //   isActive(false);
  // };

  const handleLinkClick = (id, child = []) => {
    dispatch(setSelectedId({ id }));
    console.log(child.length, "проверка child");
    if (child.length > 0) {
      return;
    } else {
      close(false);
    }
  };

  const handleMenuClick = (id) => {
    if (activeMenuItem === id) {
      // Если subMenu уже открыто, закрыть его
      setActiveMenuItem(null);
    } else {
      // В противном случае, открыть subMenu для выбранного id
      setActiveMenuItem(id);
    }
  };

  useEffect(() => {
    dispatch(loadbyNavbar());
  }, [dispatch]);

  const menuClassName = !isActive
    ? `${stl.elementsMenu} ${stl.menuActive}`
    : stl.elementsMenu;

  return (
    <div onClick={(e) => e.stopPropagation()}>
      {/* <div className={stl.wrapper} onClick={close}> */}
      <ul className={menuClassName}>
        <li className={stl.menuItem}>
          {promo && (
            <Link
              to={`category/promo`}
              className={stl.navbar_item_header}
              // onClick={() => handleLinkClick("promo")}
            >
              Акции
            </Link>
          )}
          <div className={stl.menuSeparator}></div>{" "}
          {type.map((c, index) => (
            <>
              <div
                key={index}
                className={`${stl.menuItemWrapper} ${
                  activeMenuItem === c.id ? stl.active : ""
                }`}
                onClick={() => handleMenuClick(c.id)}>
                <Link
                  to={`category/${c.id}`}
                  className={stl.navbar_item_header}
                  onClick={() => handleLinkClick(c.id, c.children)}>
                  {c.name}
                </Link>

                {c.children?.length > 0 && (
                  <div className={stl.subMenu}>
                    {/* <div className={subMenu}> */}
                    <ul className={stl.downElementsMenu}>
                      {c.children.map((childType) => (
                        <li key={childType.id} className={stl.menuItemSub}>
                          <Link
                            to={`category/${childType.id}`}
                            className={stl.navbar_item_header}
                            onClick={() => handleLinkClick(childType.id)}>
                            {childType.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              <div className={stl.menuSeparator}></div>{" "}
              {/* Элемент разделителя */}
            </>
          ))}
        </li>
        <div className={stl.info}>
          <Link to="/dostavka" className={stl.about} onClick={close}>
            О доставке
          </Link>
          <div className={stl.menuSeparator}></div>{" "}
          <div className={stl.phone}>
            <a className={stl.ahref} href="tel:+79272770404">
              +7 (927) 277-04-04
            </a>
          </div>
          {/* <div className={stl.email}>shop.info@gmail.com</div> */}
          <br />
          {/* <div className={stl.cart}>
            <CartPopup />
          </div> */}
        </div>
      </ul>
    </div>
  );
};

export default BurgerMenu;
